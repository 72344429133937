import React, { useState } from "react";
// import { navigate } from "gatsby";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import AffiliateForm from "../../../page-components/affiliates/form";
import bg from "../../../images/svg/vector-14.svg";

const AffiliatePage = () => {
  const [step, setStep] = useState(1);
  // useEffect(() => {
  //   navigate("/partners/become-an-ally/");
  // }, []);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width"
    >
      <SEO title="SpotOn Affiliate" />

      <img src={bg} alt="background" className="absolute top-0 right-0 " />

      <AffiliateForm step={step} setStep={setStep} />
    </Layout>
  );
};

export default AffiliatePage;
